import cn from 'clsx';
import { disableUIBlocker, enableUIBlocker } from 'main/components/common';
import { errorToast, infoToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params/schemas/RedirectStatus';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import { NUVEI_TWINT_CANCEL_ERROR_CODE } from 'main/services/nuvei/NuveiCardService';
import { NuveiTransactionResponse } from 'main/services/nuvei/NuveiTransactionResponse';
import NuveiTwintService from 'main/services/nuvei/NuveiTwintService';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PayWithNuveiTwintBtn: FC = () => {
  const { t } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();

  return (
    <div className="relative">
      {
        <div className={cn('flex flex-col mt-md', 'visible')}>
          <div className={cn('flex mt-md')}>
            <PayButton
              onClick={async () => {
                try {
                  enableUIBlocker();

                  const result = await NuveiTwintService.createNewPayment();

                  if (result.result === 'APPROVED') {
                    if (result.errCode === '0') {
                      // "Ok" button was clicked in the TWINT app
                      setClarityTag('pay', [
                        RedirectStatus.Success,
                        paymentModuleT(PaymentModuleId.NuveiTwint),
                      ]);
                      NavigationService.navigateTo({
                        page: InternalPage.StatusSuccess,
                      });
                    } else {
                      errorToast(
                        t('Something went wrong!'),
                        t(
                          'Operation failed. Please try again with a different payment method.'
                        )
                      );
                      setClarityTag('pay', [
                        RedirectStatus.Failure,
                        paymentModuleT(PaymentModuleId.NuveiTwint),
                      ]);
                    }
                  } else if (result.result === 'DECLINED') {
                    // "Cancel" button was clicked in the TWINT app

                    //errCode = 0 - when TWINT mobile app shows just 'Cancel' buttons and user clicked 'Cancel'
                    //errCode = 1218 - when TWINT mobile app shows both 'Ok' and 'Cancel' buttons and user clicked 'Cancel'
                    //error code 1218 is not listed here https://docs.nuvei.com/documentation/integration/response-handling/ , so it's hard to say what exactly this means
                    infoToast(
                      t('Select a new payment method and proceed to pay.')
                    );
                    setClarityTag('pay', [
                      RedirectStatus.Canceled,
                      paymentModuleT(PaymentModuleId.NuveiTwint),
                    ]);
                  }
                } catch (e) {
                  const error = e as Error;
                  TelemetryService.trackException(
                    error,
                    'PayWithNuveiTwintBtn',
                    {
                      pid: PaymentStore.paymentIntentId,
                    }
                  );
                  Logger.error(
                    LoggerOrigin.ReactComponent,
                    'PayWithNuveiTwintBtn failed to pay with Nuvei TWINT.',
                    error
                  );

                  const response = e as NuveiTransactionResponse;
                  if (response.result === 'ERROR') {
                    if (response.errCode === NUVEI_TWINT_CANCEL_ERROR_CODE) {
                      //'Return' or Close popup
                      infoToast(
                        t('Select a new payment method and proceed to pay.')
                      );
                      setClarityTag('pay', [
                        RedirectStatus.Canceled,
                        paymentModuleT(PaymentModuleId.NuveiTwint),
                      ]);
                    } else {
                      errorToast(
                        t('Something went wrong!'),
                        t(
                          'Operation failed. Please try again with a different payment method.'
                        )
                      );
                      setClarityTag('pay', [
                        RedirectStatus.Failure,
                        paymentModuleT(PaymentModuleId.NuveiTwint),
                      ]);
                    }
                  }
                } finally {
                  disableUIBlocker();
                }
              }}
              title={t('Proceed to pay')}
              disabled={false}
            />
          </div>
        </div>
      }
    </div>
  );
};
