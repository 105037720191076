interface CommonPaymentInstrument {
  readonly id: number;
  readonly moduleId: PaymentModuleId;
  readonly maskedInfo: string;
}

export interface PaymentModule {
  readonly moduleIcon: InstrumentIcon;
}

export interface PaymentInstrument extends CommonPaymentInstrument {
  readonly expirationDate?: string;
  readonly iconName: InstrumentIcon;
  readonly brand?: InstrumentBrand;
  readonly status: InstrumentStatus;
  readonly legalEntityNumber: number | undefined;
  readonly isDefault: boolean;
  readonly isDuplicate: boolean;
  /*
  The first 6 digits of a debit or credit card are known as the bank identification number (BIN), or issuer identification number (IIN).
  This is needed for Braintree, cause it's a required param now.
  Apparently, for GooglePay 4 digits for BIN is Ok
  */
  readonly bin: string;
}

export enum InstrumentIcon {
  Amex = 'amex',
  Diners = 'diners',
  Discover = 'discover',
  Elo = 'elo',
  Jcb = 'jcb',
  MasterCard = 'mastercard',
  Maestro = 'maestro',
  UnionPay = 'unionpay',
  Visa = 'visa',
  PayPal = 'paypal',
  TransbankWebpay = 'transbank',
  Ideal = 'ideal',
  Subsidiary = 'subsidiary',
  ApplePay = 'applepay',
  Bancontact = 'bancontact',
  GenericCard = 'genericcard',
  GooglePay = 'googlepay',
  DirectDebit = 'directdebit',
  ECommerceConnect = 'ecommerceconnect',
  NuveiTwint = 'nuveitwint',
  SEPA = 'sepa',
  Delivery = 'delivery',
  OnlinePayment = 'onlinepayment',
  BankTransfer = 'branktransfer',
  Yookassa = 'yookassa',
  DirectDebitPostFinance = 'directdebitpostfinance',
}

export enum InstrumentBrand {
  Amex = 1,
  Diners,
  Discover,
  Elo,
  Jcb,
  MasterCard,
  Maestro,
  UnionPay,
  Visa,
  PayPal,
  DirectDebit,
  Yookassa,
  DirectDebitPostFinance,
  DirectDebitLSV,
}

export enum InstrumentStatus {
  Valid,
  Expired,

  // This status is set when the instrument cannot be used due to
  // issues regarding service failures that prevent us from using them.
  TemporarilyUnavailable,
}

export enum PaymentModuleId {
  WorldpayCard = 4,
  BraintreePayPal = 6,
  BraintreeCard = 7,
  Transbank = 8,
  PaymentAtSubsidiary = 9,
  Sepa = 10,
  WorldpayIdeal = 11,
  WorldpayBancontact = 12,
  BraintreeApplePay = 13,
  BraintreeGooglePay = 14,
  DirectDebitLSV = 15,
  ECommerceConnect = 17,
  NuveiTwint = 18,
  FlutterwaveCreditCard = 19,
  NuveiCreditCard = 20,
  BraintreeSepa = 21,
  TwoCTwoP = 22,
  PaymentOnDelivery = 23,
  BankTransfer = 24,
  Finvoice = 25,
  ProcessingKZ = 26,
  Yookassa = 27,
  RazorPay = 28,
  DirectDebitPostFinance = 29,
}
