import { execGet, execPost } from '../utils/RequestUtils';

export interface RazorPayApiResponse {
  readonly redirectUrl: string;
}

export async function getRazorPayRedirectUrl(
  paymentIntentId: string,
  locale: string,
  telemetryConsent = false
): Promise<string> {
  const response = await execGet<RazorPayApiResponse>(
    `paymentintent/${paymentIntentId}/razorpay/url?tc=${telemetryConsent}&locale=${locale}`
  );

  return response.redirectUrl;
}
