import {
  disableUIBlocker,
  enableUIBlocker,
  LoadingSpinner,
  LoadingSpinnerSize,
} from 'main/components/common';
import { errorToast, infoToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import BraintreeGooglePayService from 'main/services/braintree/BraintreeGooglePayService';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { Component, createRef, FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

export const PayWithBraintreeGooglePayBtn: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();

  return (
    <GooglePayButton
      locale={language as SupportedLocale}
      t={t}
      paymentModuleT={paymentModuleT}
    />
  );
};

interface GooglePayButtonProps {
  readonly locale: SupportedLocale;
  readonly t: (value: string) => string;
  readonly paymentModuleT: (moduleId: PaymentModuleId) => string;
}
class GooglePayButton extends Component<GooglePayButtonProps> {
  private ref: RefObject<HTMLDivElement>;

  constructor(props: GooglePayButtonProps) {
    super(props);
    this.ref = createRef<HTMLDivElement>();
  }

  componentDidMount() {
    this.injectButton();
  }

  componentDidUpdate(prevProps: GooglePayButtonProps) {
    if (this.props.locale !== prevProps.locale) {
      this.injectButton();
    }
  }

  render() {
    return (
      <div className="h-14 flex flex-col justify-center">
        <div className="h-11" ref={this.ref}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
      </div>
    );
  }

  private injectButton() {
    if (!this.ref.current) {
      return;
    }

    // Create google pay button
    const btn = BraintreeGooglePayService.createButton(this.props.locale, {
      onSuccess: this.onSuccess,
      onCancel: this.onCancel,
      onError: this.onError,
      onModalOpen: this.onModalOpen,
    });

    // Replace div content with newly created button
    this.ref.current.innerHTML = '';
    this.ref.current.appendChild(btn);
  }

  private onModalOpen = () => {
    enableUIBlocker();
  };

  private onSuccess = () => {
    if (PaymentStore.flow === PaymentIntentFlow.Manage) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('status', RedirectStatus.Success);
      window.location.reload();
    } else {
      NavigationService.navigateTo({ page: InternalPage.StatusSuccess });
      disableUIBlocker();
    }
    setClarityTag('pay', [
      RedirectStatus.Success,
      this.props.paymentModuleT(PaymentModuleId.BraintreeGooglePay),
    ]);
  };

  private onCancel = () => {
    disableUIBlocker();
    infoToast(this.props.t('Select a new payment method and proceed to pay.'));
    setClarityTag('pay', [
      RedirectStatus.Canceled,
      this.props.paymentModuleT(PaymentModuleId.BraintreeGooglePay),
    ]);
  };

  private onError = (error: Error) => {
    disableUIBlocker();

    TelemetryService.trackException(error, 'PayWithBraintreeGooglePayBtn', {
      pid: PaymentStore.paymentIntentId,
    });
    Logger.error(
      LoggerOrigin.ReactComponent,
      'PayWithBraintreeGooglePayBtn failed to add Google Pay card.',
      error
    );

    setClarityTag('pay', [
      RedirectStatus.Failure,
      this.props.paymentModuleT(PaymentModuleId.BraintreeGooglePay),
    ]);
    errorToast(
      this.props.t('Something went wrong!'),
      this.props.t(
        'Operation failed. Please try again with a different payment method.'
      )
    );
  };
}
