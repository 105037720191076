import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { IPaymentIntent, PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import { AppTheme } from 'main/schemas/Theme';

import { InvalidFlowError } from '../errors/InvalidFlowError';
import { InvalidThemeError } from '../errors/InvalidThemeError';
import { ApiModuleName } from '../schemas/ApiModuleNames';
import { ApiPaymentIntent } from './../schemas/ApiPaymentIntent';

export function parseApiPaymentIntent(
  intent: ApiPaymentIntent
): IPaymentIntent {
  return {
    ...intent,
    customer: {
      ...intent.customer,
      flow: parseFlow(intent.customer.flow),
      theme: parseTheme(intent.customer.theme ?? AppTheme.PM),
      allowedPaymentMethods: parsePaymentMethods(
        intent.customer.allowedPaymentMethods ?? []
      ),
      preSelectedPaymentMethod: mapApiModuleNameToModuleId(
        intent.customer.preSelectedPaymentMethod
      ),
      skip3DS: intent.customer.skip3DS,
    },
  };
}

function parseFlow(flow: string): PaymentIntentFlow {
  if (Object.values(PaymentIntentFlow).includes(flow as PaymentIntentFlow)) {
    return flow as PaymentIntentFlow;
  }

  throw new InvalidFlowError(flow);
}

function parseTheme(theme: string): AppTheme {
  if (Object.values(AppTheme).includes(theme as AppTheme)) {
    return theme as AppTheme;
  }

  throw new InvalidThemeError(theme);
}

export function parsePaymentMethods(
  methods: ReadonlyArray<string>
): ReadonlyArray<PaymentModuleId> {
  return methods
    .map((m) => {
      return mapApiModuleNameToModuleId(m);
    })
    .filter((x) => x !== undefined) as PaymentModuleId[];
}

export function mapApiModuleNameToModuleId(
  m: string
): PaymentModuleId | undefined {
  switch (m as ApiModuleName) {
    case ApiModuleName.BraintreeApplePay:
      return PaymentModuleId.BraintreeApplePay;
    case ApiModuleName.BraintreeCard:
      return PaymentModuleId.BraintreeCard;
    case ApiModuleName.BraintreeGooglePay:
      return PaymentModuleId.BraintreeGooglePay;
    case ApiModuleName.BraintreePayPal:
      return PaymentModuleId.BraintreePayPal;
    case ApiModuleName.WorldpayBancontact:
      return PaymentModuleId.WorldpayBancontact;
    case ApiModuleName.WorldpayCard:
      return PaymentModuleId.WorldpayCard;
    case ApiModuleName.WorldpayIdeal:
      return PaymentModuleId.WorldpayIdeal;
    case ApiModuleName.PaymentAtSubsidiary:
      return PaymentModuleId.PaymentAtSubsidiary;
    case ApiModuleName.Transbank:
      return PaymentModuleId.Transbank;
    case ApiModuleName.Sepa:
      return PaymentModuleId.Sepa;
    case ApiModuleName.DirectDebitLSV:
      return PaymentModuleId.DirectDebitLSV;
    case ApiModuleName.DirectDebitPostFinance:
      return PaymentModuleId.DirectDebitPostFinance;
    case ApiModuleName.ECommerceConnect:
      return PaymentModuleId.ECommerceConnect;
    case ApiModuleName.NuveiTwint:
      return PaymentModuleId.NuveiTwint;
    case ApiModuleName.FlutterwaveCreditCard:
      return PaymentModuleId.FlutterwaveCreditCard;
    case ApiModuleName.NuveiCreditCard:
      return PaymentModuleId.NuveiCreditCard;
    case ApiModuleName.BraintreeSepa:
      return PaymentModuleId.BraintreeSepa;
    case ApiModuleName.PaymentOnDelivery:
      return PaymentModuleId.PaymentOnDelivery;
    case ApiModuleName.BankTransfer:
      return PaymentModuleId.BankTransfer;
    case ApiModuleName.Finvoice:
      return PaymentModuleId.Finvoice;
    case ApiModuleName.TwoCTwoP:
      return PaymentModuleId.TwoCTwoP;
    case ApiModuleName.ProcessingKZ:
      return PaymentModuleId.ProcessingKZ;
    case ApiModuleName.Yookassa:
      return PaymentModuleId.Yookassa;
    case ApiModuleName.RazorPay:
      return PaymentModuleId.RazorPay;
    default:
      return undefined;
  }
}
