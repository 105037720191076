import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { IPaymentIntent } from 'main/schemas/PaymentIntent';
import { AppTheme } from 'main/schemas/Theme';
import { getWindowParam } from 'main/utils/url';

import { determineMethodPreSelection } from '../utils/InstrumentUtils';
import { CannotFetchIntentError } from './errors/CannotFetchIntentError';
import {
  getPaymentIntentById,
  getPaymentIntentByNonceToken,
} from './PaymentIntentApi';

export class PaymentIntentStore {
  public readonly name = 'PaymentIntentStore';

  private _paymentIntent!: IPaymentIntent;
  private _allowedPaymentModules: ReadonlyArray<PaymentModuleId> = [];

  public startup(): Promise<void> {
    const nonceToken = getWindowParam('nonceToken');
    const paymentIntentId = getWindowParam('pid');

    let promise: Promise<IPaymentIntent>;
    if (nonceToken) {
      promise = getPaymentIntentByNonceToken(nonceToken);
    } else if (paymentIntentId) {
      promise = getPaymentIntentById(paymentIntentId);
    } else {
      return Promise.reject(new CannotFetchIntentError());
    }

    return promise.then(this.handlePaymentIntent);
  }

  get id() {
    return this._paymentIntent.id;
  }

  get currency() {
    return this._paymentIntent.order.currency;
  }

  get orderTotal() {
    return this._paymentIntent.order.amount;
  }

  get orderNumber() {
    return this._paymentIntent.order.number;
  }

  get flow() {
    return this._paymentIntent.customer.flow;
  }

  get theme() {
    return this._paymentIntent.customer.theme ?? AppTheme.PM;
  }

  get cancelUrl() {
    return this._paymentIntent.urls.cancel ?? undefined;
  }

  get successUrl() {
    return this._paymentIntent.urls.success ?? undefined;
  }

  get customerEmail() {
    return this._paymentIntent.customer.email ?? '';
  }

  get customerCountry() {
    return this._paymentIntent.customer.shippingAddress.countryCode ?? '';
  }

  get customerShippingAddress() {
    return this._paymentIntent.customer.shippingAddress ?? '';
  }

  get customerName() {
    const firstName = this._paymentIntent.customer?.firstName;
    const lastName = this._paymentIntent.customer?.lastName;

    return `${firstName} ${lastName}`.trim();
  }

  get legalEntity() {
    return this._paymentIntent.customer.legalEntityNumber;
  }

  get allowedPaymentModules() {
    return this._allowedPaymentModules;
  }

  get rawPreSelectedPaymentMethod() {
    return this._paymentIntent.customer.preSelectedPaymentMethod;
  }

  get preSelectedModule() {
    return determineMethodPreSelection(
      this._paymentIntent.customer.preSelectedPaymentMethod,
      this._paymentIntent.customer.allowedPaymentMethods
    );
  }

  get skip3DS() {
    return this._paymentIntent.customer.skip3DS;
  }

  /**
   * Given an array of modules representing the services successfuly started,
   * force update the allowed payment methods to remove the failed ones from
   * the list.
   *
   * All payment method related services are optional and will only start
   * depending on API configurations and device support. The validation is
   * done by each service individually, hence we should render the options
   * for the ones that are allowed and running. This allows for a service to
   * fail startup and still give users the option to pay with other methods.
   *
   * @param runningModules list of PaymentModuleIds representing running
   * OptionalStartupables
   */
  public forceUpdateAllowedPaymentMethods(
    runningModules: ReadonlyArray<PaymentModuleId>
  ) {
    this._allowedPaymentModules = this._allowedPaymentModules.filter(
      (allowedMethodByApi) => runningModules.includes(allowedMethodByApi)
    );
  }

  private handlePaymentIntent = (intent: IPaymentIntent): void => {
    this._paymentIntent = intent;
    this._allowedPaymentModules = intent.customer
      .allowedPaymentMethods as number[];
  };
}
