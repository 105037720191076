import cn from 'clsx';
import {
  disableUIBlocker,
  enableUIBlocker,
  FilledBadge,
  LoadingSpinner,
  LoadingSpinnerSize,
  Paragraph,
  PaymentIcon,
  QuestionMarkIcon,
} from 'main/components/common';
import { AlertWarning } from 'main/components/common/alerts';
import { BadgeType } from 'main/components/common/badges/enums';
import { Tooltip } from 'main/components/common/others/Tooltip/Tooltip';
import { errorToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { useUserFriendlyPrefixForInstrumentBrand } from 'main/hooks/useUserFriendlyPrefixForInstrumentBrands';
import {
  InstrumentIcon,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import BraintreeCardService from 'main/services/braintree/BraintreeCardService';
import { BraintreeError } from 'main/services/braintree/errors/BraintreeError';
import { isUnsupportedCard } from 'main/services/braintree/helpers/BraintreeValidationHelper';
import { BTCCValidationResults } from 'main/services/braintree/schemas/ValidationResults';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import { mapBrandNameToInstrumentBrand } from 'main/services/payments/instruments/PaymentInstrumentsHelper';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const styles = {
  hostedFieldContainer:
    'relative rounded-field bg-secondary h-14 p-sm focused-container',
  hostedFieldContainerInError: 'border-2 border-error',
};

export const BraintreeCardForm: FC = () => {
  const { t } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();
  const instrumentBrandPrefixer = useUserFriendlyPrefixForInstrumentBrand();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [cardTypeBeingWritten, setCardTypeBeingWritten] =
    useState<InstrumentIcon>(InstrumentIcon.GenericCard);
  const [formValidation, setFormValidation] = useState<BTCCValidationResults>();
  const [searchParams, setSearchParams] = useSearchParams();

  const commonErrorMessage = useMemo(() => {
    return (
      <div className="absolute left-1 -top-3.5">
        <FilledBadge type={BadgeType.Error} text={t('Invalid')} />
      </div>
    );
  }, [t]);

  const submitCardForm = () => {
    enableUIBlocker();

    let brandPrefix = '';

    if (cardTypeBeingWritten !== undefined) {
      const brand = mapBrandNameToInstrumentBrand(cardTypeBeingWritten);
      if (brand !== undefined) {
        const prefixer = instrumentBrandPrefixer(brand);
        if (prefixer !== undefined) {
          brandPrefix = prefixer.toUpperCase();
        }
      }
    }

    let promise;
    if (PaymentStore.flow === PaymentIntentFlow.Moto) {
      promise = BraintreeCardService.authorizeWithoutVerification();
    } else if (PaymentStore.flow === PaymentIntentFlow.Manage) {
      promise = BraintreeCardService.verifyAndTokenizeNewInstrument();
    } else {
      promise = BraintreeCardService.verifyAndAuthorizeNewInstrument();
    }
    promise
      .then(() => {
        if (PaymentStore.flow === PaymentIntentFlow.Manage) {
          searchParams.set('status', RedirectStatus.Success);
          setSearchParams(searchParams);
          window.location.reload();
        } else {
          NavigationService.navigateTo({ page: InternalPage.StatusSuccess });
          disableUIBlocker();
        }
        setClarityTag('pay', [
          RedirectStatus.Success,
          paymentModuleT(PaymentModuleId.BraintreeCard),
        ]);
      })
      .catch((err: Error | undefined) => {
        disableUIBlocker();
        TelemetryService.trackException(err, 'BraintreeCardForm');
        Logger.error(
          LoggerOrigin.ReactComponent,
          'BraintreeCardForm failed to verify the card or authorize it.',
          err
        );
        setClarityTag('pay', [
          RedirectStatus.Failure,
          paymentModuleT(PaymentModuleId.BraintreeCard),
        ]);

        if (err !== undefined && err.name === 'BraintreeError') {
          const braintreeError = err as BraintreeError;
          const isUnsupportedMethodByBraintreeMerchant =
            braintreeError !== undefined && isUnsupportedCard(braintreeError);

          if (isUnsupportedMethodByBraintreeMerchant) {
            if (brandPrefix === '') {
              errorToast(
                t('Unsupported payment method!'),
                t('The selected payment method can not be added.')
              );
            } else {
              errorToast(
                t('Unsupported payment method!'),
                t('{{brandPrefix}} payment method can not be added.', {
                  brandPrefix: brandPrefix,
                })
              );
            }
          }
        } else {
          errorToast(
            t('Something went wrong!'),
            t(
              'Operation failed. Please try again with a different payment method.'
            )
          );
        }

        /**
         * Braintree 3DS card verification gets stuck when attempting to authorize again
         * with the same data. Reinitializing solves the issue.
         */
        BraintreeCardService.reInit3DS();
      });
  };

  useEffect(() => {
    setLoading(true);
    BraintreeCardService.setupHostedFields(
      {
        cardholderName: {
          container: '#cc-name',
          placeholder: t('Cardholder Name'),
        },
        number: {
          container: '#cc-number',
          placeholder: t('Card Number'),
        },
        cvv: { container: '#cc-cvv', placeholder: 'CVV' },
        expirationDate: {
          container: '#cc-expiration',
          placeholder: 'MM/YY',
        },
      },
      setCardTypeBeingWritten,
      setFormValidation
    )
      .catch((err) => {
        TelemetryService.trackException(err, 'BraintreeCardForm');
        Logger.error(
          LoggerOrigin.ReactComponent,
          'BraintreeCardForm failed to initialize form.',
          err
        );
        setError(err);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative">
      {loading && !error && (
        <div className={'absolute w-full h-full flex justify-center'}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
      )}
      {error && (
        <AlertWarning
          title={t('Something went wrong!')}
          description={t('Card payments are currently unavailable.')}
        />
      )}
      {!error && (
        <>
          <div
            className={cn(
              'flex flex-col mt-md',
              // We need the elements to be added to the DOM so that braintree SDK
              // can find them and initialize them. We hide them until all is ready.
              // In the meantime, a loading indicator is shown
              loading ? 'invisible' : 'visible'
            )}
          >
            <div
              className={cn(
                styles.hostedFieldContainer,
                formValidation?.invalidIds.includes('#cc-name') &&
                  styles.hostedFieldContainerInError
              )}
              id="cc-name"
            >
              {formValidation?.invalidIds.includes('#cc-name') &&
                commonErrorMessage}
            </div>
            <div
              className={cn(
                styles.hostedFieldContainer,
                formValidation?.invalidIds.includes('#cc-number') &&
                  styles.hostedFieldContainerInError,
                'relative mt-sm'
              )}
              id="cc-number"
            >
              {formValidation?.invalidIds.includes('#cc-number') &&
                commonErrorMessage}
              <div className="absolute top-3 right-4">
                <PaymentIcon icon={cardTypeBeingWritten} />
              </div>
            </div>
            <div className="flex flex-row mt-sm">
              <div
                className={cn(
                  styles.hostedFieldContainer,
                  formValidation?.invalidIds.includes('#cc-expiration') &&
                    styles.hostedFieldContainerInError
                )}
                id="cc-expiration"
              >
                {formValidation?.invalidIds.includes('#cc-expiration') &&
                  commonErrorMessage}
              </div>
              <div
                className={cn(
                  styles.hostedFieldContainer,
                  formValidation?.invalidIds.includes('#cc-cvv') &&
                    styles.hostedFieldContainerInError,
                  'relative ml-sm'
                )}
                id="cc-cvv"
              >
                {!loading && !error && (
                  <div className="absolute top-4 right-4">
                    <Tooltip
                      tooltip={
                        <span className="opacity-40">
                          <QuestionMarkIcon />
                        </span>
                      }
                    >
                      <div className="w-tip h-full space-y-xs">
                        <Paragraph>{t('Where do I find the CVV?')}</Paragraph>
                        <img
                          className="w-full h-full"
                          alt={t('Where do I find the CVV?')}
                          src="/images/cc-cvv.png"
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={cn(loading ? 'hidden' : 'flex mt-md')}>
            <PayButton
              onClick={() => submitCardForm()}
              title={
                PaymentStore.flow === PaymentIntentFlow.Manage
                  ? t('Add new card')
                  : t('Proceed to pay')
              }
              disabled={
                !formValidation?.isFilled ||
                formValidation?.invalidIds.length > 0
              }
            />
          </div>
        </>
      )}
    </div>
  );
};
