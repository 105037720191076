// Define a constant enum for country codes
export enum IbanCountryCode {
  DE = 'DE', // Germany
  AT = 'AT', // Austria
  FR = 'FR', // France
  BE = 'BE', // Belgium
  LU = 'LU', // Luxembourg
  FI = 'FI', // Finland
  NL = 'NL', // Netherlands,
  CH = 'CH', // Switzerland
  CY = 'CY', // Cyprus
  EE = 'EE', // Estonia
  GR = 'GR', // Greece
  IT = 'IT', // Italy
  IE = 'IE', // Ireland
  LV = 'LV', // Latvia
  LT = 'LT', // Lithuania
  MT = 'MT', // Malta
  PT = 'PT', // Portugal
  SK = 'SK', // Slovakia
  SI = 'SI', // Slovenia
  ES = 'ES', // Spain
}

// IBAN Length for each country
const IBAN_LENGTHS = {
  [IbanCountryCode.DE]: 22,
  [IbanCountryCode.AT]: 20,
  [IbanCountryCode.FR]: 27,
  [IbanCountryCode.BE]: 16,
  [IbanCountryCode.LU]: 20,
  [IbanCountryCode.FI]: 18,
  [IbanCountryCode.NL]: 18,
  [IbanCountryCode.CH]: 21,
  [IbanCountryCode.CY]: 28,
  [IbanCountryCode.EE]: 20,
  [IbanCountryCode.GR]: 27,
  [IbanCountryCode.IT]: 27,
  [IbanCountryCode.IE]: 22,
  [IbanCountryCode.LV]: 21,
  [IbanCountryCode.LT]: 20,
  [IbanCountryCode.MT]: 31,
  [IbanCountryCode.PT]: 25,
  [IbanCountryCode.SK]: 24,
  [IbanCountryCode.SI]: 19,
  [IbanCountryCode.ES]: 24,
};

export function isValidIban(iban: string) {
  // Remove spaces and convert to uppercase
  iban = iban.replace(/\s+/g, '').toUpperCase();

  // Check if the country code is valid and the IBAN has the correct length
  const countryCode = iban.slice(0, 2) as IbanCountryCode;

  if (!(countryCode in IBAN_LENGTHS)) {
    return false;
  }

  const ibanLength = IBAN_LENGTHS[countryCode];

  if (iban.length !== ibanLength) {
    return false;
  }

  if (!isValidIbanFormat(iban, countryCode)) {
    return false;
  }

  // Rearrange IBAN for checksum validation (move first 4 characters to the end)
  const rearrangedIban = iban.slice(4) + iban.slice(0, 4);

  // Convert letters to numbers: A = 10, B = 11, ..., Z = 35
  const numericIban = rearrangedIban.replace(/[A-Z]/g, (char) =>
    (char.charCodeAt(0) - 55).toString()
  );

  // Perform the modulus 97 check
  return mod97(numericIban) === 1;
}

// Function to perform modulus 97 operation on a large number (string)
function mod97(stringValue: string) {
  let checksum = Number(stringValue.slice(0, 2)); // Convert initial checksum to a number

  for (let i = 2; i < stringValue.length; i++) {
    // Concatenate checksum and the current character, then convert to a number
    checksum = Number(String(checksum) + stringValue[i]);
    checksum = checksum % 97; // Apply the modulus operation
  }

  return checksum;
}

// Function to validate country-specific IBAN formats using regex
export function isValidIbanFormat(iban: string, countryCode: IbanCountryCode) {
  switch (countryCode) {
    case IbanCountryCode.DE: // Germany
      return /^DE[0-9]{2}[0-9]{8}[0-9]{10}$/.test(iban);
    case IbanCountryCode.AT: // Austria
      return /^AT[0-9]{2}[0-9]{5}[0-9]{11}$/.test(iban);
    case IbanCountryCode.FR: // France
      return /^FR[0-9]{2}[0-9]{5}[0-9]{5}[a-zA-Z0-9]{11}[0-9]{2}$/.test(iban);
    case IbanCountryCode.BE: // Belgium
      return /^BE[0-9]{2}[0-9]{3}[0-9]{9}$/.test(iban);
    case IbanCountryCode.LU: // Luxembourg
      return /^LU[0-9]{2}[0-9]{3}[0-9]{13}$/.test(iban);
    case IbanCountryCode.FI: // Finland
      return /^FI[0-9]{2}[0-9]{6}[0-9]{8}$/.test(iban);
    case IbanCountryCode.NL: // Netherlands
      return /^NL[0-9]{2}[A-Z]{4}[0-9]{10}$/.test(iban);
    case IbanCountryCode.CY: // Cyprus
      return /^CY[0-9]{2}[0-9]{3}[0-9]{5}[0-9]{16}$/.test(iban);
    case IbanCountryCode.EE: // Estonia
      return /^EE[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{11}[0-9]{1}$/.test(iban);
    case IbanCountryCode.GR: // Greece
      return /^GR[0-9]{2}[0-9]{3}[0-9]{4}[0-9]{16}$/.test(iban);
    case IbanCountryCode.IT: // Italy
      return /^IT[0-9]{2}[A-Z]{1}[0-9]{10}[0-9]{12}$/.test(iban);
    case IbanCountryCode.IE: // Ireland
      return /^IE[0-9]{2}[A-Z]{4}[0-9]{6}[0-9]{8}$/.test(iban);
    case IbanCountryCode.LV: // Latvia
      return /^LV[0-9]{2}[A-Z]{4}[0-9]{13}$/.test(iban);
    case IbanCountryCode.LT: // Lithuania
      return /^LT[0-9]{2}[0-9]{5}[0-9]{11}$/.test(iban);
    case IbanCountryCode.MT: // Malta
      return /^MT[0-9]{2}[A-Z]{4}[0-9]{5}[0-9]{18}$/.test(iban);
    case IbanCountryCode.PT: // Portugal
      return /^PT[0-9]{2}[0-9]{4}[0-9]{4}[0-9]{11}[0-9]{2}$/.test(iban);
    case IbanCountryCode.SK: // Slovakia
      return /^SK[0-9]{2}[0-9]{4}[0-9]{6}[0-9]{10}$/.test(iban);
    case IbanCountryCode.SI: // Slovenia
      return /^SI[0-9]{2}[0-9]{5}[0-9]{8}[0-9]{2}$/.test(iban);
    case IbanCountryCode.ES: // Spain
      return /^ES[0-9]{2}[0-9]{4}[0-9]{4}[0-9]{1}[0-9]{1}[0-9]{10}$/.test(iban);
    case IbanCountryCode.CH: // Switzerland
      return /^CH[0-9]{2}[0-9]{5}[0-9]{12}$/.test(iban);
    default:
      return false; // Unsupported country code
  }
}
