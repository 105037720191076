import { InstrumentUrlApiResponse } from 'main/services/base/schemas';
import {
  execDelete,
  execGet,
  execPost,
  execPatch,
} from 'main/services/utils/RequestUtils';

import {
  ManagePaymentInstrumentApiResponse,
  PaymentInstrumentApiResponse,
} from './PaymentInstrumentsApiResponse';
import { parseManageResponse, parseResponse } from './PaymentInstrumentsHelper';
import { TokenizationPayload } from './TokenizationPayload';

export async function getPaymentInstruments(paymentIntentId: string) {
  const response = await execGet<PaymentInstrumentApiResponse[]>(
    `paymentintent/${paymentIntentId}/paymentdata`
  );

  return parseResponse(response);
}

export async function getManagePaymentInstruments(
  customerNumber: string,
  legalEntityNumber: number | undefined
) {
  const searchParams = new URLSearchParams({
    customerNumber: customerNumber,
  });

  if (legalEntityNumber) {
    searchParams.append('legalEntityNumber', legalEntityNumber.toString());
  }

  const response = await execGet<ManagePaymentInstrumentApiResponse[]>(
    `paymentinstruments?${searchParams.toString()}`
  );

  return parseManageResponse(response);
}

export async function paymentInstrumentTokenize(
  payload: TokenizationPayload
): Promise<string> {
  const response = await execPost<InstrumentUrlApiResponse>(
    `paymentinstruments`,
    {
      legalEntityNumber: payload.legalEntityNumber,
      customerNumber: payload.customerNumber,
      paymentDataTypeId: payload.paymentModuleId,
      braintreeInstrument: payload.braintreeInstrument,
    }
  );

  return response.redirectUrl;
}

export function deletePaymentInstrument(
  paymentInstrumentId: number
): Promise<void> {
  return execDelete(`paymentdata/${paymentInstrumentId}`);
}

export function changeDefaultPaymentInstrument(
  paymentInstrumentId: number
): Promise<void> {
  return execPatch<void>(
    `paymentinstruments/${paymentInstrumentId}/default`,
    {}
  );
}
