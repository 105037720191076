import cn from 'clsx';
import {
  disableUIBlocker,
  enableUIBlocker,
  PaymentsLayout,
} from 'main/components/common';
import { errorToast, infoToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { useUserFriendlyPrefixForInstrumentBrand } from 'main/hooks/useUserFriendlyPrefixForInstrumentBrands';
import { SupportedLocale } from 'main/i18n';
import {
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import BraintreeCardService from 'main/services/braintree/BraintreeCardService';
import BraintreeGooglePayService from 'main/services/braintree/BraintreeGooglePayService';
import BraintreePayPalService from 'main/services/braintree/BraintreePayPalService';
import BraintreeSepaService from 'main/services/braintree/BraintreeSepaService';
import { BraintreeError } from 'main/services/braintree/errors/BraintreeError';
import { isUnsupportedCard } from 'main/services/braintree/helpers/BraintreeValidationHelper';
import { setClarityTag } from 'main/services/clarity';
import LSVService from 'main/services/directdebit/LSVService';
import PostFinanceService from 'main/services/directdebit/PostFinanceService';
import SepaService from 'main/services/directdebit/SepaService';
import FlutterwaveCardService from 'main/services/flutterwave/FlutterwaveCardService';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import NuveiCardService, {
  NUVEI_CARDS_CANCEL_ERROR_CODE,
} from 'main/services/nuvei/NuveiCardService';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import WorldpayCardService from 'main/services/worldpay/WorldpayCardService';
import { redirect } from 'main/utils/redirect';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { RadioGroup } from '../../common/forms/inputs/RadioGroup';
import { CancelPaymentButton } from './utils/CancelPaymentButton';
import { computeRadioOptions } from './utils/helper';
import { NewInstrumentButton } from './utils/NewInstrumentButton';
import { PayButton } from './utils/PayButton';
import { RedirectUrlButton } from './utils/RedirectUrlButton';

export const StoredInstrumentsPage: FC<{
  readonly instruments: ReadonlyArray<PaymentInstrument>;
  readonly flow: PaymentIntentFlow;
  readonly canAddInstruments?: boolean;
}> = ({ instruments, flow, canAddInstruments = true }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();

  const [selectedInstrumentId, setSelectedInstrumentId] = useState<number>();
  const instrumentBrandPrefixer = useUserFriendlyPrefixForInstrumentBrand();
  const [searchParams, setSearchParams] = useSearchParams();

  const triggerAuthorizeForInstrument = useCallback(
    (instrumentId: number) => {
      const instrument = instruments.find((i) => i.id === instrumentId);

      if (!instrument) {
        return;
      }

      let brandPrefix = '';

      if (instrument.brand !== undefined) {
        const brand = instrumentBrandPrefixer(instrument.brand);
        if (brand !== undefined) {
          brandPrefix = brand.toString().toUpperCase();
        }
      }

      let promise = Promise.resolve();
      const currentPage = NavigationService.getInternalPage();
      NavigationService.navigateTo({ page: InternalPage.StatusProcessing });

      switch (instrument.moduleId) {
        case PaymentModuleId.BraintreeCard:
          enableUIBlocker();
          promise =
            BraintreeCardService.verifyAndAuthorizeInstrument(instrument);
          break;
        case PaymentModuleId.BraintreeGooglePay:
          promise = BraintreeGooglePayService.authorizeInstrument(instrument);
          break;
        case PaymentModuleId.BraintreePayPal:
          promise = BraintreePayPalService.authorize(undefined, instrument.id);
          break;
        case PaymentModuleId.BraintreeSepa:
          promise = BraintreeSepaService.authorizeInstrument(instrument);
          break;
        case PaymentModuleId.WorldpayCard:
          promise = new Promise((resolve, reject) => {
            //resolve argument (1-st) will never be called, but we need it to to keep order of the params
            WorldpayCardService.getRedirectUrl(
              language as SupportedLocale,
              instrument.id
            )
              .then((s) => {
                redirect(s);
              })
              .catch((err) => {
                reject(err);
              });
          });
          break;
        case PaymentModuleId.FlutterwaveCreditCard:
          promise = new Promise((resolve, reject) => {
            //resolve argument (1-st) will never be called, but we need it to to keep order of the params
            FlutterwaveCardService.getRedirectUrl(
              language as SupportedLocale,
              instrument.id
            )
              .then((s) => {
                redirect(s);
              })
              .catch((err) => {
                reject(err);
              });
          });
          break;
        case PaymentModuleId.Sepa:
          promise = SepaService.authorize(instrument.id);
          break;
        case PaymentModuleId.DirectDebitLSV:
          promise = LSVService.authorize(instrument.id);
          break;
        case PaymentModuleId.DirectDebitPostFinance:
          promise = PostFinanceService.authorize(instrument.id);
          break;
        case PaymentModuleId.NuveiCreditCard:
          promise = NuveiCardService.initTransactionAndSafeCharge(
            instrument.id
          ).then(() => NuveiCardService.createCITPayment());
          break;
        default: {
          const exception = new Error(
            `Instrument for moduleId ${instrument.moduleId} should not be stored! How did this happen?`
          );
          exception.name = 'UnexpectedModuleIdError';
          promise = Promise.reject(exception);
          Logger.error(
            LoggerOrigin.ReactComponent,
            'Unexpected moduleId.',
            exception
          );
        }
      }

      promise
        .then(() => {
          NavigationService.navigateTo({ page: InternalPage.StatusSuccess });
          setClarityTag('pay', [
            RedirectStatus.Success,
            'Stored instrument used',
            paymentModuleT(instrument.moduleId),
          ]);
        })
        .catch((err: Error) => {
          if (
            PaymentStore.externalPreselectedInstrument !== undefined &&
            searchParams.has('instrumentId')
          ) {
            searchParams.delete('instrumentId');
            setSearchParams(searchParams);
          }

          PaymentStore.denyPreselectedInstrumentPayment();
          TelemetryService.trackException(err, 'StoredInstrumentsPage');
          Logger.error(
            LoggerOrigin.ReactComponent,
            'Failed to authorize instrument',
            instrument,
            err
          );
          NavigationService.navigateTo(currentPage);

          if (NuveiCardService.isNuveiError(err)) {
            TelemetryService.trackException(err, 'NuveiCreditCard', {
              pid: PaymentStore.paymentIntentId,
            });

            if (err.errCode === NUVEI_CARDS_CANCEL_ERROR_CODE) {
              infoToast(t('Select a new payment method and proceed to pay.'));
              setClarityTag('pay', [
                RedirectStatus.Canceled,
                'Stored instrument used',
                paymentModuleT(instrument.moduleId),
              ]);
            } else {
              errorToast(
                t('Something went wrong!'),
                t(
                  'Operation failed. Please try again with a different payment method.'
                )
              );
              setClarityTag('pay', [
                RedirectStatus.Failure,
                'Stored instrument used',
                paymentModuleT(instrument.moduleId),
              ]);
            }
          } else if (err !== undefined && err.name === 'BraintreeError') {
            const braintreeError = err as BraintreeError;
            const isUnsupportedMethodByBraintreeMerchant =
              braintreeError !== undefined && isUnsupportedCard(braintreeError);

            if (isUnsupportedMethodByBraintreeMerchant) {
              if (brandPrefix === '') {
                errorToast(
                  t('Unsupported payment method!'),
                  t(
                    'The selected payment method is not available for this transaction.'
                  )
                );
              } else {
                errorToast(
                  t('Unsupported payment method!'),
                  t(
                    '{{brandPrefix}} payment method cannot be used for this transaction.',
                    { brandPrefix: brandPrefix }
                  )
                );
              }
              setClarityTag('pay', [
                RedirectStatus.Failure,
                'Stored instrument used',
                paymentModuleT(instrument.moduleId),
              ]);
            }
          } else {
            errorToast(
              t('Something went wrong!'),
              t(
                'Operation failed. Please try again with a different payment method.'
              )
            );

            setClarityTag('pay', [
              RedirectStatus.Failure,
              'Stored instrument used',
              paymentModuleT(instrument.moduleId),
            ]);
          }
        })
        .finally(() => disableUIBlocker());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const payWithSelectedMethod = useCallback(() => {
    if (selectedInstrumentId !== undefined) {
      triggerAuthorizeForInstrument(selectedInstrumentId);
    }
  }, [selectedInstrumentId, triggerAuthorizeForInstrument]);

  useEffect(() => {
    if (
      PaymentStore.externalPreselectedInstrument !== undefined &&
      PaymentStore.isPreselectedInstrumentPaymentAllowed
    ) {
      payWithSelectedMethod();
    }
  }, [payWithSelectedMethod]);

  const radioOptions = useMemo(
    () => computeRadioOptions(instruments),
    [instruments]
  );

  const { pageTitle, pageFooter, pageNavigation, pageMainAction } =
    useMemo(() => {
      if (flow === PaymentIntentFlow.Manage) {
        return {
          pageTitle: t('Edit your payment methods'),
          pageFooter: null,
          pageNavigation:
            PaymentStore.redirectUrl !== undefined ? (
              <RedirectUrlButton />
            ) : null,
          pageMainAction: null,
        };
      }

      return {
        pageTitle: t('Select your payment method'),
        pageFooter: <CancelPaymentButton />,
        pageNavigation: null,
        pageMainAction: (
          <PayButton
            key={selectedInstrumentId}
            disabled={
              radioOptions.length === 0 || selectedInstrumentId === undefined
            }
            onClick={() => {
              payWithSelectedMethod();
            }}
          />
        ),
      };
    }, [
      flow,
      t,
      selectedInstrumentId,
      radioOptions.length,
      payWithSelectedMethod,
    ]);

  const pageContent = useMemo(() => {
    if (flow === PaymentIntentFlow.Manage) {
      return (
        <>
          {(radioOptions ?? []).map((opt) => {
            return (
              <div
                key={opt.id}
                className={cn(
                  'relative mt-xs first:mt-0 flex items-center border-2 border-secondary rounded-field',
                  opt.disabled && 'bg-secondary opacity-50'
                )}
              >
                {opt.label}
              </div>
            );
          })}
        </>
      );
    }

    return (
      <RadioGroup
        preSelectedValue={PaymentStore.preSelectedInstrument?.id.toString()}
        onSelectionChange={(instrumentIdAsString) =>
          instrumentIdAsString
            ? setSelectedInstrumentId(Number(instrumentIdAsString))
            : setSelectedInstrumentId(undefined)
        }
        name="stored-instruments"
        options={radioOptions ?? []}
      />
    );
  }, [flow, radioOptions]);

  return (
    <div
      className={cn(
        PaymentStore.externalPreselectedInstrument !== undefined &&
          PaymentStore.isPreselectedInstrumentPaymentAllowed
          ? 'invisible'
          : 'visible'
      )}
    >
      <PaymentsLayout
        title={pageTitle}
        footer={pageFooter}
        navigation={pageNavigation}
        mainAction={pageMainAction}
        flow={flow}
      >
        {pageContent}
        {canAddInstruments && <NewInstrumentButton flow={flow} />}
      </PaymentsLayout>
    </div>
  );
};
