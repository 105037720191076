import {
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import { AppTheme } from 'main/schemas/Theme';
import { getWindowParam } from 'main/utils/url';

import { DebitMandateDetails } from '../../schemas/DebitMandateDetails';
import { PaymentServicesFailedError } from '../base/errors/PaymentServicesFailedError';
import { TBootstrapMode } from '../base/ServiceRegistrar';
import { Startupable } from '../base/Startupable';
import { registerStartupable } from '../base/StartupHelper';
import { getBraintreeClientToken } from '../braintree/helpers/BraintreeApi';
import { excludeBraintreeSepaForSafari } from '../braintree/helpers/BraintreeSepaHelper';
import { PaymentInstrumentsManageStore } from './instruments/PaymentInstrumentsManageStore';
import { PaymentInstrumentsStore } from './instruments/PaymentInstrumentsStore';
import { PaymentIntentStore } from './intent/PaymentIntentStore';

class PaymentStore implements Startupable {
  private _paymentInstrumentsManageStore!: PaymentInstrumentsManageStore;
  private _paymentInstrumentsStore!: PaymentInstrumentsStore;
  private _paymentIntentStore!: PaymentIntentStore;

  // TODO: Delete all of these properties to avoid mantaining 2 copies of the same
  // information. Use the getters to get the value from the right store.
  private _externalPreselectedInstrument: PaymentInstrument | undefined; // passed from outside (e.g. from url) instrument that will be preselected
  private _isPreselectedInstrumentPaymentAllowed = true; //flag for paying with preselected from outside instrument - this is needed to avoid infinite loop of re-rendering in case of failure
  private _preSelectedModule: PaymentModuleId | undefined;
  // The flow needs to be defined always. For the manage startup, it will be PaymentIntentFlow.Manage.
  // For the others, it will be the flow defined inside the Payment Intent.
  private _flow!: PaymentIntentFlow;
  private _rawPreSelectedPaymentMethod: PaymentModuleId | undefined;
  private _paymentIntentId = '';
  private _currency = '';
  private _orderTotal = 0;
  private _orderNumber: string | undefined;
  private _cancelUrl: string | undefined;
  private _customerEmail!: string;
  private _customerCountryCode!: string;
  private _customerStreet = '';
  private _customerCity = '';
  private _customerRegion = '';
  private _customerZipCode = '';
  private _customerName = '';
  private _braintreePaypalToken: string | undefined;
  private _braintreeDefaultToken: string | undefined;
  private _directDebitMandateDetails: DebitMandateDetails | undefined;

  public readonly name = 'PaymentStore';

  constructor() {
    registerStartupable(this, []);
  }

  public async startup(mode: TBootstrapMode): Promise<void> {
    if (mode === 'payment-manage') {
      this._paymentInstrumentsManageStore = new PaymentInstrumentsManageStore();
      await this._paymentInstrumentsManageStore.init();
      this._flow = PaymentIntentFlow.Manage;
      this._currency = this._paymentInstrumentsManageStore.currency;
      this._preSelectedModule = this.allowedPaymentModules[0];
      this._customerEmail = this._paymentInstrumentsManageStore.customerEmail;
      this._customerCountryCode =
        this._paymentInstrumentsManageStore.customerCountry;
      this._customerStreet = this._paymentInstrumentsManageStore.customerStreet;
      this._customerCity = this._paymentInstrumentsManageStore.customerCity;
      this._customerRegion = this._paymentInstrumentsManageStore.customerRegion;
      this._customerZipCode =
        this._paymentInstrumentsManageStore.customerZipCode;
      this._customerName = this._paymentInstrumentsManageStore.customerName;
    } else {
      this._paymentIntentStore = new PaymentIntentStore();
      await this._paymentIntentStore.startup();
      this._paymentInstrumentsStore = new PaymentInstrumentsStore(
        this._paymentIntentStore.rawPreSelectedPaymentMethod
      );
      await this._paymentInstrumentsStore.init(this._paymentIntentStore.id);
      this._flow = this._paymentIntentStore.flow;
      this._externalPreselectedInstrument =
        this._paymentInstrumentsStore.externalPreselectedInstrument;
      this._preSelectedModule = this._paymentIntentStore.preSelectedModule;
      this._rawPreSelectedPaymentMethod =
        this._paymentIntentStore.rawPreSelectedPaymentMethod;
      this._paymentIntentId = this._paymentIntentStore.id;
      this._currency = this._paymentIntentStore.currency;
      this._orderTotal = this._paymentIntentStore.orderTotal;
      this._orderNumber = this._paymentIntentStore.orderNumber;
      this._cancelUrl = this._paymentIntentStore.cancelUrl;
      this._customerEmail = this._paymentIntentStore.customerEmail;
      this._customerCountryCode = this._paymentIntentStore.customerCountry;
      this._customerStreet =
        this._paymentIntentStore.customerShippingAddress.streetAddress;
      this._customerCity =
        this._paymentIntentStore.customerShippingAddress.locality;
      this._customerRegion =
        this._paymentIntentStore.customerShippingAddress.region;
      this._customerZipCode =
        this._paymentIntentStore.customerShippingAddress.postalCode;
      this._customerName = this._paymentIntentStore.customerName;
    }
  }

  get paymentIntentId() {
    return this._paymentIntentId;
  }

  get currency() {
    return this._currency;
  }

  get orderTotal() {
    return this._orderTotal;
  }

  get orderNumber() {
    return this._orderNumber;
  }

  get theme(): AppTheme {
    if (this._paymentIntentStore !== undefined) {
      return this._paymentIntentStore.theme;
    }

    const themeFromUrl = getWindowParam('theme');
    if (
      themeFromUrl !== undefined &&
      Object.values(AppTheme).includes(themeFromUrl.toLowerCase() as AppTheme)
    ) {
      return themeFromUrl as AppTheme;
    }

    return AppTheme.PM;
  }

  get cancelUrl() {
    return this._cancelUrl;
  }

  get redirectUrl() {
    // This one allows to keep backwards compatibility with the intent based manage flow
    if (this._paymentIntentStore !== undefined) {
      return this._paymentIntentStore.successUrl;
    }

    if (this._paymentInstrumentsManageStore !== undefined) {
      return this._paymentInstrumentsManageStore.redirectUrl;
    }

    return undefined;
  }

  get successUrl() {
    if (this._paymentIntentStore !== undefined) {
      return this._paymentIntentStore.successUrl;
    }

    return undefined;
  }

  get customerEmail() {
    return this._customerEmail;
  }

  get customerCountryCode() {
    return this._customerCountryCode;
  }

  get customerStreet() {
    return this._customerStreet;
  }

  get customerCity() {
    return this._customerCity;
  }

  get customerRegion() {
    return this._customerRegion;
  }

  get customerZipCode() {
    return this._customerZipCode;
  }

  get customerName() {
    return this._customerName;
  }

  get legalEntity() {
    if (this._paymentIntentStore !== undefined) {
      return this._paymentIntentStore.legalEntity;
    }

    const legalEntityFromUrl = getWindowParam('le');
    if (!isNaN(Number(legalEntityFromUrl))) {
      return Number(legalEntityFromUrl);
    }

    return undefined;
  }

  get allowedPaymentModules() {
    if (this._paymentIntentStore !== undefined) {
      const paymentModules = [
        ...this._paymentIntentStore.allowedPaymentModules,
      ];

      return excludeBraintreeSepaForSafari(paymentModules);
    }

    if (this._paymentInstrumentsManageStore !== undefined) {
      const paymentModules = [
        ...this._paymentInstrumentsManageStore.allowedPaymentModules,
      ];
      return excludeBraintreeSepaForSafari(paymentModules);
    }

    return [];
  }

  get rawPreSelectedPaymentMethod() {
    return this._rawPreSelectedPaymentMethod;
  }

  get flow() {
    return this._flow;
  }

  get instruments() {
    if (this._paymentInstrumentsStore !== undefined) {
      return this._paymentInstrumentsStore.instruments;
    }

    if (this._paymentInstrumentsManageStore !== undefined) {
      return this._paymentInstrumentsManageStore.instruments;
    }

    return [];
  }

  get preSelectedInstrument() {
    if (this._paymentInstrumentsStore !== undefined) {
      return this._paymentInstrumentsStore.preSelectedInstrument;
    }

    return undefined;
  }

  get preSelectedModule() {
    return this._preSelectedModule;
  }

  get externalPreselectedInstrument() {
    return this._externalPreselectedInstrument;
  }

  get isPreselectedInstrumentPaymentAllowed() {
    return this._isPreselectedInstrumentPaymentAllowed;
  }

  get skip3DS() {
    if (this._paymentIntentStore !== undefined) {
      return this._paymentIntentStore.skip3DS;
    }

    if (this._paymentInstrumentsManageStore !== undefined) {
      return this._paymentInstrumentsManageStore.skip3DS;
    }

    //by default we should trigger 3ds. Skipping is exceptional
    return false;
  }

  denyPreselectedInstrumentPayment() {
    //set flag to false
    this._isPreselectedInstrumentPaymentAllowed = false;
  }

  set directDebitMandate(value: DebitMandateDetails | undefined) {
    this._directDebitMandateDetails = value;
  }

  get directDebitMandate() {
    return this._directDebitMandateDetails;
  }

  async getBraintreePaypalToken(): Promise<string | undefined> {
    if (this._braintreePaypalToken !== undefined) {
      return Promise.resolve(this._braintreePaypalToken);
    }

    if (this._paymentInstrumentsManageStore !== undefined) {
      this._braintreePaypalToken =
        this._paymentInstrumentsManageStore.braintreeClientTokens?.paypalToken;
      return Promise.resolve(this._braintreePaypalToken);
    }

    if (this._paymentIntentId !== '') {
      return getBraintreeClientToken(
        this.paymentIntentId,
        PaymentModuleId.BraintreePayPal
      ).then((paypalToken) => {
        this._braintreePaypalToken = paypalToken;
        return this._braintreePaypalToken;
      });
    }

    return Promise.reject();
  }

  async getBraintreeDefaultToken(): Promise<string | undefined> {
    if (this._braintreeDefaultToken !== undefined) {
      return Promise.resolve(this._braintreeDefaultToken);
    }

    if (this._paymentInstrumentsManageStore !== undefined) {
      this._braintreeDefaultToken =
        this._paymentInstrumentsManageStore.braintreeClientTokens?.defaultToken;
      return Promise.resolve(this._braintreeDefaultToken);
    }

    if (this.paymentIntentId !== '') {
      return getBraintreeClientToken(
        this.paymentIntentId,
        //this could be any non-Paypal Braintree payment module
        PaymentModuleId.BraintreeCard
      ).then((defaultToken) => {
        this._braintreeDefaultToken = defaultToken;
        return this._braintreeDefaultToken;
      });
    }

    return Promise.reject();
  }

  forceUpdateInstrumentStatus(
    startedModules: ReadonlyArray<PaymentModuleId>
  ): void {
    if (startedModules.length === 0) {
      throw new PaymentServicesFailedError();
    }

    if (
      this._paymentIntentStore !== undefined &&
      this._paymentInstrumentsStore !== undefined
    ) {
      this._paymentIntentStore.forceUpdateAllowedPaymentMethods(startedModules);
      this._paymentInstrumentsStore.forceUpdateInstrumentStatus(startedModules);
      return;
    }

    if (this._paymentInstrumentsManageStore !== undefined) {
      this._paymentInstrumentsManageStore.forceUpdateAllowedPaymentMethods(
        startedModules
      );
      return;
    }
  }
}

export default new PaymentStore();
