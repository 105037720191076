import {
  Paragraph,
  PaymentIcon,
  PlusIcon,
  SimpleButton,
} from 'main/components/common';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import { InstrumentIconForModuleId } from 'main/services/payments/instruments/PaymentInstrumentTypes';
import PaymentStore from 'main/services/payments/PaymentStore';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  container:
    'w-full flex flex-row justify-between hover:border-focus min-h-14 px-sm py-xs border-2 border-secondary rounded-field',
  button: 'flex flex-row items-center text-left space-x-xs text-gray',
  iconsContainer: 'ml-xs max-w-1/2 text-right space-x-sm',
};

export const NewInstrumentButton: FC<{
  readonly flow: PaymentIntentFlow;
}> = ({ flow }) => {
  const { t } = useTranslation();
  const supportedMethods = Array.from(
    new Set(
      PaymentStore.allowedPaymentModules.map(
        (moduleId) => InstrumentIconForModuleId[moduleId]
      )
    )
  );

  return (
    <div className="w-full mt-xs">
      <SimpleButton
        className="w-full"
        onClick={() =>
          NavigationService.navigateTo({ page: InternalPage.PaymentMethods })
        }
      >
        <div className={styles.container}>
          <span className={styles.button}>
            <PlusIcon />
            <Paragraph>
              {flow === PaymentIntentFlow.Manage
                ? t('Add new payment method')
                : t('Select a new payment method')}
            </Paragraph>
          </span>
          <div className={styles.iconsContainer}>
            {supportedMethods.map((moduleIcon) => (
              <PaymentIcon key={moduleIcon} icon={moduleIcon} />
            ))}
          </div>
        </div>
      </SimpleButton>
    </div>
  );
};
