import { ICustomer } from 'main/schemas/Customer';
import {
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import AuthenticationService from 'main/services/auth/AuthenticationService';
import { getCustomerInformation } from 'main/services/customer/CustomerApi';
import { getWindowParam } from 'main/utils/url';

import { parsePaymentMethods } from '../intent/helpers/PaymentIntentHelper';
import { getManagePaymentInstruments } from './PaymentInstrumentsApi';
import { IBraintreeClientTokens } from './PaymentInstrumentsApiResponse';
import { getInstrumentsConfig } from './PaymentInstrumentsConfigApi';

export class PaymentInstrumentsManageStore {
  public readonly name = 'PaymentInstrumentsManageStore';

  private _instruments: ReadonlyArray<PaymentInstrument> = [];
  private _allowedPaymentModules: ReadonlyArray<PaymentModuleId> = [];
  private _braintreeClientTokens: IBraintreeClientTokens | undefined;
  private _currency = '';
  private _redirectUrl: string | undefined;
  private _skip3DS: boolean | undefined;
  private _customer: ICustomer | undefined;

  get flow(): PaymentIntentFlow {
    return PaymentIntentFlow.Manage;
  }

  public async init(): Promise<void> {
    const customerNumber = AuthenticationService.user.tpId;
    const legalEntityNumber = Number(getWindowParam('le'));

    if (customerNumber) {
      const [instruments, customer] = await Promise.all([
        getManagePaymentInstruments(customerNumber, legalEntityNumber),
        getCustomerInformation(customerNumber),
      ]);

      this._instruments = instruments;
      this._customer = customer;
    }

    const refererUrl = getWindowParam('return');
    if (refererUrl) {
      this._redirectUrl = decodeURI(refererUrl);
    }

    if (legalEntityNumber) {
      const newInstrumentConfig = await getInstrumentsConfig(legalEntityNumber);

      this._allowedPaymentModules = parsePaymentMethods(
        newInstrumentConfig.paymentMethods
      );
      this._braintreeClientTokens = newInstrumentConfig.braintreeClientTokens;
      this._currency = newInstrumentConfig.currency;
      this._skip3DS = newInstrumentConfig.skip3DS;
    }
  }

  get customerRegion() {
    return this._customer?.region ?? '';
  }

  get customerCity() {
    return this._customer?.city ?? '';
  }

  get customerZipCode() {
    return this._customer?.zipCode ?? '';
  }

  get customerStreet() {
    return this._customer?.street ?? '';
  }

  get customerCountry() {
    return this._customer?.countryCode ?? '';
  }

  get customerEmail() {
    return this._customer?.email ?? '';
  }

  get customerName() {
    const firstName = this._customer?.firstName;
    const lastName = this._customer?.lastName;

    return `${firstName} ${lastName}`.trim();
  }

  get instruments() {
    return this._instruments;
  }

  get allowedPaymentModules() {
    return this._allowedPaymentModules;
  }

  get braintreeClientTokens() {
    return this._braintreeClientTokens;
  }

  get currency() {
    return this._currency;
  }

  get redirectUrl() {
    return this._redirectUrl;
  }

  get skip3DS() {
    return this._skip3DS;
  }

  public forceUpdateAllowedPaymentMethods(
    runningModules: ReadonlyArray<PaymentModuleId>
  ) {
    this._allowedPaymentModules = this._allowedPaymentModules.filter(
      (allowedMethodByApi) => runningModules.includes(allowedMethodByApi)
    );
  }
}
