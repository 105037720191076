import cn from 'clsx';
import { disableUIBlocker, enableUIBlocker } from 'main/components/common';
import { errorToast, infoToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import BraintreeSepaService from 'main/services/braintree/BraintreeSepaService';
import {
  BraintreeError,
  BraintreeErrorCodes,
} from 'main/services/braintree/errors/BraintreeError';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const BraintreeSepaForm: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const paymentModuleT = useUserFriendlyPaymentModuleName();

  const [searchParams, setSearchParams] = useSearchParams();

  const [accountHolderNameValue, setAccountHolderNameValue] = useState('');
  const [accountHolderNameValid, setAccountHolderNameValid] = useState(false);
  const [ibanValue, setIbanValue] = useState('');
  const [ibanValid, setIbanValid] = useState(false);

  const ibanFieldRef = useRef<HTMLInputElement>(null);
  const accountHolderNameFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ibanValue?.trim()?.length <= 0) {
      setIbanValid(false);
      ibanFieldRef.current?.classList?.remove('border-transparent');
      ibanFieldRef.current?.classList?.add('border-error');
    } else {
      setIbanValid(true);
      ibanFieldRef.current?.classList?.remove('border-error');
      ibanFieldRef.current?.classList?.add('border-transparent');
    }

    if (accountHolderNameValue?.trim()?.length <= 0) {
      setAccountHolderNameValid(false);
      accountHolderNameFieldRef.current?.classList?.remove(
        'border-transparent'
      );
      accountHolderNameFieldRef.current?.classList?.add('border-error');
    } else {
      setAccountHolderNameValid(true);
      accountHolderNameFieldRef.current?.classList?.remove('border-error');
      accountHolderNameFieldRef.current?.classList?.add('border-transparent');
    }
  }, [ibanValue, accountHolderNameValue]);

  const submitSepaForm = async (accountHolderName: string, iban: string) => {
    enableUIBlocker();

    BraintreeSepaService.submitSepaForm(
      accountHolderName,
      iban,
      language as SupportedLocale
    )
      .then(() => {
        if (PaymentStore.flow === PaymentIntentFlow.Manage) {
          searchParams.set('status', RedirectStatus.Success);
          setSearchParams(searchParams);
          window.location.reload();
        } else {
          NavigationService.navigateTo({ page: InternalPage.StatusSuccess });
          disableUIBlocker();
        }
        setClarityTag('pay', [
          RedirectStatus.Success,
          paymentModuleT(PaymentModuleId.BraintreeSepa),
        ]);
      })
      .catch((error: Error) => {
        disableUIBlocker();
        TelemetryService.trackException(error, 'BraintreeSepaForm', {
          pid: PaymentStore.paymentIntentId,
        });
        Logger.error(
          LoggerOrigin.ReactComponent,
          'BraintreeSepaForm failed to add SEPA mandate.',
          error
        );

        if (error !== undefined && error.name === 'BraintreeError') {
          const braintreeError = error as BraintreeError;

          if (
            braintreeError.code === BraintreeErrorCodes.SepaCreateMandateFailed
          ) {
            setClarityTag('pay', [
              RedirectStatus.Failure,
              paymentModuleT(PaymentModuleId.BraintreeSepa),
            ]);

            errorToast(
              t('Something went wrong!'),
              t(
                'Operation failed. Please try again with a different payment method.'
              )
            );
          } else if (
            braintreeError.code === BraintreeErrorCodes.SepaCustomerCanceled
          ) {
            setClarityTag('pay', [
              RedirectStatus.Canceled,
              paymentModuleT(PaymentModuleId.BraintreeSepa),
            ]);
            infoToast(t('Select a new payment method and proceed to pay.'));
          }
        } else {
          setClarityTag('pay', [
            RedirectStatus.Failure,
            paymentModuleT(PaymentModuleId.BraintreeSepa),
          ]);

          errorToast(
            t('Something went wrong!'),
            t(
              'Operation failed. Please try again with a different payment method.'
            )
          );
        }
      });
  };

  return (
    <div className="relative">
      <input
        id="accountHolderName"
        placeholder={t('Account Holder Name')}
        type="text"
        value={accountHolderNameValue}
        onChange={(e) => setAccountHolderNameValue(e.target.value)}
        className="relative rounded-field bg-secondary h-14 p-sm focused-container w-full border-2 border-transparent"
        ref={accountHolderNameFieldRef}
      />

      <input
        id="iban"
        placeholder="IBAN"
        type="text"
        value={ibanValue}
        onChange={(e) => setIbanValue(e.target.value)}
        className="mt-2 relative rounded-field bg-secondary h-14 p-sm focused-container w-full border-2 border-transparent"
        ref={ibanFieldRef}
      />

      <div className={cn('flex mt-md')}>
        <PayButton
          onClick={() => submitSepaForm(accountHolderNameValue, ibanValue)}
          title={
            PaymentStore.flow === PaymentIntentFlow.Manage
              ? t('Add new SEPA mandate')
              : t('Proceed to pay')
          }
          disabled={!ibanValid || !accountHolderNameValid}
        />
      </div>
    </div>
  );
};
