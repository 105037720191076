import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useUserFriendlyPaymentModuleName = () => {
  const { t } = useTranslation();

  const mapIdToName = useMemo(() => {
    return (moduleId: PaymentModuleId) => {
      switch (moduleId) {
        case PaymentModuleId.BraintreeCard:
          return t('Credit or debit card');
        case PaymentModuleId.WorldpayCard:
          return t('Credit or debit card');
        case PaymentModuleId.BraintreePayPal:
          return 'PayPal';
        case PaymentModuleId.Transbank:
          return 'Transbank';
        case PaymentModuleId.Finvoice:
          return t('E-invoice');
        case PaymentModuleId.PaymentAtSubsidiary:
          return t('Payment at your Subsidiary');
        case PaymentModuleId.Sepa:
          return t('Direct debit');
        case PaymentModuleId.WorldpayIdeal:
          return 'iDeal';
        case PaymentModuleId.BraintreeApplePay:
          return 'Apple Pay';
        case PaymentModuleId.WorldpayBancontact:
          return 'Bancontact';
        case PaymentModuleId.BraintreeGooglePay:
          return 'Google Pay';
        case PaymentModuleId.DirectDebitLSV:
          return 'LSV+';
        case PaymentModuleId.DirectDebitPostFinance:
          return 'PostFinance';
        case PaymentModuleId.ECommerceConnect:
          return 'ECommerce Connect';
        case PaymentModuleId.NuveiTwint:
          return 'TWINT';
        case PaymentModuleId.FlutterwaveCreditCard:
          return t('Credit or debit card');
        case PaymentModuleId.NuveiCreditCard:
          return t('Credit or debit card');
        case PaymentModuleId.BraintreeSepa:
          return t('Direct debit SEPA');
        case PaymentModuleId.PaymentOnDelivery:
          return t('Payment on Delivery');
        case PaymentModuleId.BankTransfer:
          return t('Bank transfer');
        case PaymentModuleId.TwoCTwoP:
          return t('Online Payment');
        case PaymentModuleId.ProcessingKZ:
          return t('Online Payment');
        case PaymentModuleId.Yookassa:
          return t('Online Payment');
        case PaymentModuleId.RazorPay:
          return t('Online Payment');
      }
    };
  }, [t]);

  return mapIdToName;
};
