import {
  PageLayout,
  SimpleButton,
  ArrowIcon,
  disableUIBlocker,
  BoldHeading,
} from 'main/components/common';
import { errorToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params/schemas/RedirectStatus';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { SupportedLocale } from 'main/i18n';
import { ParentComponent } from 'main/schemas/ParentComponent';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import BankTransferService from 'main/services/banktransfer/BankTransferService';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { formatPrice } from 'main/utils/formatters';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PayButton } from '../stored-intruments/utils/PayButton';
import { BankTransferDetails } from './BankTransferDetails';

const styles = {
  loadingSpinner: 'flex items-center justify-center',
  pageContent: 'p-sm md:p-md 2xl:p-lg',
  pageContentHeader:
    'sticky top-0 bg-primary p-sm md:p-md 2xl:p-lg z-50 text-white',
  pageContentNavigation: 'flex',
  pageContentFooter: 'mt-md flex w-full justify-between',
  pageStickyContainer:
    'sticky bottom-0 border-0 z-50 bg-transparent pb-xs pt-sm',
  pageInnerStickyContainer: 'flex justify-center',
};

export const BankTransferDetailsPage: FC<ParentComponent> = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();

  useEffect(() => {
    disableUIBlocker();
  }, []);

  const headerText = useMemo(() => {
    return t('Total: {{totalPrice}}', {
      totalPrice: formatPrice(
        language as SupportedLocale,
        PaymentStore.currency,
        PaymentStore.orderTotal
      ),
    });
  }, [t, language]);

  return (
    <PageLayout
      headerDescription={
        PaymentStore.orderNumber
          ? `${t('Order')}: ${PaymentStore.orderNumber}`
          : undefined
      }
    >
      <div className={styles.pageContentHeader}>
        <BoldHeading>{headerText}</BoldHeading>
      </div>
      <div className="m-sm md:m-md">
        <div>
          <SimpleButton
            onClick={() =>
              NavigationService.navigateTo({
                page: InternalPage.PaymentMethods,
              })
            }
          >
            <span className={'flex flex-row items-center justify-center'}>
              <ArrowIcon direction="left" />
              <span className={'ml-xs'}>{t('Back')}</span>
            </span>
          </SimpleButton>
        </div>
        <br />
        <BankTransferDetails />
        <br />
        <div className={'flex flex-row items-center justify-center wrap'}>
          <br />
          <PayButton
            title={t('Proceed')}
            onClick={() => {
              const currentPage = NavigationService.getInternalPage();
              NavigationService.navigateTo({
                page: InternalPage.StatusProcessing,
              });
              BankTransferService.authorise()
                .then(() => {
                  NavigationService.navigateTo({
                    page: InternalPage.StatusSuccess,
                  });
                  setClarityTag('pay', [
                    RedirectStatus.Success,
                    paymentModuleT(PaymentModuleId.BankTransfer),
                  ]);
                })
                .catch((err) => {
                  NavigationService.navigateTo(currentPage);
                  TelemetryService.trackException(
                    err,
                    'BankTransferDetailsPage'
                  );
                  Logger.error(
                    LoggerOrigin.ReactComponent,
                    'Bank transfer failed',
                    err
                  );
                  errorToast(
                    t('Something went wrong!'),
                    t('Failed to authorize Bank transfer.')
                  );
                  setClarityTag('pay', [
                    RedirectStatus.Failure,
                    paymentModuleT(PaymentModuleId.BankTransfer),
                  ]);
                });
            }}
          />
        </div>
      </div>
    </PageLayout>
  );
};
