import cn from 'clsx';
import {
  disableUIBlocker,
  enableUIBlocker,
  LoadingSpinner,
  LoadingSpinnerSize,
} from 'main/components/common';
import { AlertWarning } from 'main/components/common/alerts/AlertWarning';
import { errorToast, infoToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import NuveiCardService, {
  NUVEI_CARDS_CANCEL_ERROR_CODE,
} from 'main/services/nuvei/NuveiCardService';
import { NuveiCardFieldValidationEvent } from 'main/services/nuvei/NuveiCreatePaymentInput';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import './NuveiCardForm.scss';

export const NuveiCardForm: FC = () => {
  const cardFieldDivRef = useRef<HTMLDivElement>(null);
  const nuveiCardFieldRef = useRef<any>(null);
  const { t } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();
  const [cardHolderName, setCardHolderName] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [isCardHolderFieldValid, setIsCardHolderFieldValid] = useState(false);
  const [isCardNumberFieldComplete, setIsCardNumberFieldComplete] =
    useState(false);

  useEffect(() => {
    if (cardHolderName?.trim()?.length <= 0) {
      setIsCardHolderFieldValid(false);
    } else {
      setIsCardHolderFieldValid(true);
    }
  }, [cardHolderName]);

  useEffect(() => {
    setLoading(true);

    const promise = NuveiCardService.initTransactionAndSafeCharge();
    promise
      .then((safeCharge) => {
        const safeChargeFields = safeCharge.fields({
          fonts: [
            {
              cssUrl:
                //PM's cdn with css file that containes needed fonts used in Braintree - this is needed to make Nuvei hosted files look close to Braintree
                'https://cdn.pm-international.com/assets-shared-frontend/fonts/uni-neue.css',
            },
          ],
          locale: 'en',
        });

        if (nuveiCardFieldRef.current !== null) return;

        nuveiCardFieldRef.current = safeChargeFields.create('card', {
          style: { base: { fontFamily: 'uni-neue' } },
        });

        nuveiCardFieldRef.current.attach('#card-field');
        nuveiCardFieldRef.current.on(
          'change',
          (event: NuveiCardFieldValidationEvent) => {
            if (event?.error?.type === 'validation_error') {
              if (cardFieldDivRef.current) {
                cardFieldDivRef.current?.classList?.remove(
                  'border-transparent'
                );
                cardFieldDivRef.current?.classList?.add('border-error');
              }
            } else {
              cardFieldDivRef.current?.classList?.remove('border-error');
              cardFieldDivRef.current?.classList?.add('border-transparent');
            }

            if (event?.complete) {
              setIsCardNumberFieldComplete(true);
            } else {
              setIsCardNumberFieldComplete(false);
            }
          }
        );
      })
      .catch((err) => {
        TelemetryService.trackException(err, 'NuveiCardForm');
        Logger.error(
          LoggerOrigin.ReactComponent,
          'NuveiCardForm initialization failure',
          err
        );
        setError(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const submitCardForm = async (cardNumber: any, cardHolderName: string) => {
    enableUIBlocker();
    const promise = NuveiCardService.createPayment(cardNumber, cardHolderName);
    promise
      .then(() => {
        if (PaymentStore.flow === PaymentIntentFlow.Manage) {
          searchParams.set('status', RedirectStatus.Success);
          setSearchParams(searchParams);
          window.location.reload();
        } else {
          NavigationService.navigateTo({ page: InternalPage.StatusSuccess });
          disableUIBlocker();
        }
        setClarityTag('pay', [
          RedirectStatus.Success,
          paymentModuleT(PaymentModuleId.NuveiCreditCard),
        ]);
      })
      .catch((error: Error) => {
        disableUIBlocker();
        TelemetryService.trackException(error, 'NuveiCardForm', {
          pid: PaymentStore.paymentIntentId,
        });
        Logger.error(
          LoggerOrigin.ReactComponent,
          'NuveiCardForm failed to authorize the card.',
          error
        );

        if (NuveiCardService.isNuveiError(error)) {
          if (error.errCode === NUVEI_CARDS_CANCEL_ERROR_CODE) {
            infoToast(t('Select a new payment method and proceed to pay.'));
            setClarityTag('pay', [
              RedirectStatus.Canceled,
              'Stored instrument used',
              paymentModuleT(PaymentModuleId.NuveiCreditCard),
            ]);
          } else {
            setClarityTag('pay', [
              RedirectStatus.Failure,
              paymentModuleT(PaymentModuleId.NuveiCreditCard),
            ]);
            errorToast(
              t('Something went wrong!'),
              t(
                'Operation failed. Please try again with a different payment method.'
              )
            );
          }
        } else {
          setClarityTag('pay', [
            RedirectStatus.Failure,
            paymentModuleT(PaymentModuleId.NuveiCreditCard),
          ]);
          errorToast(
            t('Something went wrong!'),
            t(
              'Operation failed. Please try again with a different payment method.'
            )
          );
        }
      });
  };

  return (
    <div className="relative">
      {loading && !error && (
        <div className={'absolute w-full h-full flex justify-center'}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
      )}
      {error && (
        <AlertWarning
          title={t('Something went wrong!')}
          description={t('Card payments are currently unavailable.')}
        />
      )}

      {!error && (
        <div>
          <input
            id="cardHolderName"
            placeholder="Cardholder name"
            type="text"
            value={cardHolderName}
            onChange={(e) => setCardHolderName(e.target.value)}
            className="relative rounded-field bg-secondary h-14 p-sm focused-container w-full"
          />

          <div
            id="card-field"
            className="relative mt-2 rounded-field bg-secondary h-14 p-sm focused-container w-full border-2 border-transparent"
            ref={cardFieldDivRef}
          />

          <div className={cn(loading ? 'hidden' : 'flex mt-md')}>
            <PayButton
              onClick={() =>
                submitCardForm(nuveiCardFieldRef.current, cardHolderName)
              }
              title={
                PaymentStore.flow === PaymentIntentFlow.Manage
                  ? t('Add new card')
                  : t('Proceed to pay')
              }
              disabled={!isCardHolderFieldValid || !isCardNumberFieldComplete}
            />
          </div>
        </div>
      )}
    </div>
  );
};
