import type { OnApproveData } from '@paypal/paypal-js';
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import { LoadingSpinner, LoadingSpinnerSize } from 'main/components/common';
import { AlertError } from 'main/components/common/alerts';
import { errorToast, infoToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import BraintreePayPalService from 'main/services/braintree/BraintreePayPalService';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const PayWithBraintreePayPalBtn: FC = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const scriptOptions = useMemo(
    () =>
      BraintreePayPalService.getPayPalScriptOptions(
        language as SupportedLocale
      ),
    [language]
  );

  return (
    <PayPalScriptProvider options={scriptOptions}>
      <PayPalButtonWrapper />
    </PayPalScriptProvider>
  );
};

const PayPalButtonWrapper: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();
  const [{ isPending }] = usePayPalScriptReducer();
  const [searchParams, setSearchParams] = useSearchParams();

  const [err, setErr] = useState<Error>();

  const buttonStyle = BraintreePayPalService.getPayPalBtnStyle(
    PaymentStore.theme
  );

  const billingAgreement = useMemo(() => {
    return () =>
      BraintreePayPalService.createBillingAgreement(
        language as SupportedLocale
      );
  }, [language]);

  const proccedToAuthorize = async (data: OnApproveData) => {
    const currentPage = NavigationService.getInternalPage();
    NavigationService.navigateTo({ page: InternalPage.StatusProcessing });

    return BraintreePayPalService.getTokenizedPayloadNonce(data)
      .then((nonce) =>
        PaymentStore.flow === PaymentIntentFlow.Manage
          ? BraintreePayPalService.tokenize(nonce)
          : BraintreePayPalService.authorize(nonce)
      )
      .then(() => {
        if (PaymentStore.flow === PaymentIntentFlow.Manage) {
          searchParams.set('status', RedirectStatus.Success);
          setSearchParams(searchParams);
          window.location.reload();
        } else {
          NavigationService.navigateTo({ page: InternalPage.StatusSuccess });
        }
        setClarityTag('pay', [
          RedirectStatus.Success,
          paymentModuleT(PaymentModuleId.BraintreePayPal),
        ]);
      })
      .catch((e: Error) => {
        NavigationService.navigateTo(currentPage);
        TelemetryService.trackException(e, 'BraintreePayPalBtn');
        Logger.error(
          LoggerOrigin.ReactComponent,
          'PayPal authorization failed.',
          e
        );
        setClarityTag('pay', [
          RedirectStatus.Failure,
          paymentModuleT(PaymentModuleId.BraintreePayPal),
        ]);
        errorToast(
          t('Something went wrong!'),
          t(
            'Operation failed. Please try again with a different payment method.'
          )
        );
        setErr(e);
      });
  };

  if (err) {
    return (
      <AlertError
        title={t('Something went wrong!')}
        description={t(
          'Operation failed. Please try again with a different payment method.'
        )}
      />
    );
  }

  if (isPending) {
    return (
      <div className="h-14">
        <LoadingSpinner size={LoadingSpinnerSize.Small} />
      </div>
    );
  }

  return (
    <div className="h-14">
      <PayPalButtons
        style={buttonStyle}
        forceReRender={[PaymentStore.theme]}
        createBillingAgreement={billingAgreement}
        onApprove={proccedToAuthorize}
        onError={(err) => {
          errorToast(
            t('Something went wrong!'),
            t('Select a new payment method and proceed to pay.')
          );
          Logger.error(LoggerOrigin.PayPalButton, 'onError', err);
          TelemetryService.trackException(undefined, 'PayPalButtons', {
            type: 'PayPalButtons.onError',
            err: err,
          });
        }}
        onCancel={() => {
          infoToast(t('Select a new payment method and proceed to pay.'));
          setClarityTag('pay', [
            RedirectStatus.Canceled,
            paymentModuleT(PaymentModuleId.BraintreePayPal),
          ]);
        }}
      />
    </div>
  );
};
