import { disableUIBlocker, enableUIBlocker } from 'main/components/common';
import { errorToast, infoToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { SupportedLocale } from 'main/i18n';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { ApplePaySupportedLocale } from 'main/services/braintree';
import BraintreeApplePayService from 'main/services/braintree/BraintreeApplePayService';
import { setClarityTag } from 'main/services/clarity';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const PayWithBraintreeApplePay: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const paymentModuleT = useUserFriendlyPaymentModuleName();

  const applePayLocale = useMemo(
    () => ApplePaySupportedLocale[language as SupportedLocale],
    [language]
  );

  return (
    // The outer wrapper is hack to control the height of the perceived button
    <div className="apple-pay-btn-container">
      <button
        // All language values in BraintreeApplePayLocale are valid lang values.
        // However, the rule axe/language throw an error when using expressions
        // since it cannot confirm the strings we assign. This hack avoids
        // flagging this as an error.
        {...{ lang: applePayLocale }}
        onClick={() => {
          enableUIBlocker();
          const currentPage = NavigationService.getInternalPage();
          NavigationService.navigateTo({ page: InternalPage.StatusProcessing });
          BraintreeApplePayService.authorize()
            .then(() => {
              NavigationService.navigateTo({
                page: InternalPage.StatusSuccess,
              });
              setClarityTag('pay', [
                RedirectStatus.Success,
                paymentModuleT(PaymentModuleId.BraintreeApplePay),
              ]);
            })
            .catch((err: Error | undefined) => {
              NavigationService.navigateTo(currentPage);

              if (err) {
                // Failed to authorize
                TelemetryService.trackException(err, 'BraintreeApplePayBtn');
                Logger.error(
                  LoggerOrigin.ReactComponent,
                  'ApplePay authorization failed',
                  err
                );
                setClarityTag('pay', [
                  RedirectStatus.Failure,
                  paymentModuleT(PaymentModuleId.BraintreeApplePay),
                ]);
                errorToast(
                  t('Something went wrong!'),
                  t(
                    'Operation failed. Please try again with a different payment method.'
                  )
                );
              } else {
                // Payment sheet dismissed/operation cancelled
                Logger.log(
                  LoggerOrigin.ReactComponent,
                  'ApplePay session aborted'
                );
                setClarityTag('pay', [
                  RedirectStatus.Canceled,
                  paymentModuleT(PaymentModuleId.BraintreeApplePay),
                ]);
                infoToast(t('Select a new payment method and proceed to pay.'));
              }
            })
            .finally(() => disableUIBlocker());
        }}
        type="button"
      >
        {''}
      </button>
    </div>
  );
};
