import { PageLayout } from 'main/components/common';
import { AlertError } from 'main/components/common/alerts';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface SomethingWentWrongProps {
  readonly err?: Error;
  readonly description?: string;
  readonly orderId?: string;
}

export const SomethingWentWrong: FC<SomethingWentWrongProps> = ({
  err,
  description,
  orderId,
}) => {
  const { t } = useTranslation();

  const errorNameToUserFriendlyTitle = useMemo(() => {
    if (!err) return t('Something went wrong!');
    switch (err.name) {
      case 'IntentAlreadyProcessedError':
        return t('Payment Already Proccessed');
      case 'CannotFetchIntentError':
        return t('Cannot Find Payment Intent');
      case 'AuthorizationError':
        return t('Not Authorized');
      default:
        return err.name;
    }
  }, [err, t]);

  const alertDescription = err ? err.message : description;

  return (
    <PageLayout
      headerDescription={orderId ? `${t('Order')}: ${orderId}` : undefined}
    >
      <AlertError
        title={errorNameToUserFriendlyTitle}
        description={alertDescription}
      />
    </PageLayout>
  );
};
